import React,{useState} from 'react'
import {Card,Row,Col,Button,Form,Select,Tabs,Input,InputNumber,Spin,Modal,DatePicker,message,Table,Collapse} from 'antd'
import { LoadingOutlined ,CaretRightOutlined} from "@ant-design/icons";
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import Axios from 'axios'
import { useGlobalContext } from "../../../lib/storage";
import { getCustomBusinessUnit,getSupplierData,getSupplierAddress} from "../../../services/generic";
import { getDeliveryLocation,getSupplierProduct,getLandedCostData } from "../../../services/custom";
import MainTable from './MainTable'
import OtherCostTable from './OtherCostTable'
import summaryTableColumns from './summaryCols'
import {serverUrl,genericUrl,fileDownloadUrl} from '../../../constants/serverConfig'
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const {Option} = Select
const {TabPane} = Tabs
const {Panel} = Collapse
const dateFormat = 'YYYY-MM-DD'

const PurchaseOrder = (props) => {
const { globalStore } = useGlobalContext();
const Themes = globalStore.userData.CW360_V2_UI;
const [bunitData,setBunitData] = useState([])
const [bunitId,setBunitId] = useState('')
const [businessUnitName,setBusinessUnitName] = useState('')
const [supplierData,setSupplierData] = useState([])
const [currentDate,setCurrentdate]= useState("")
const [supplierId,setSupplierId] = useState('')
const [supplierName,setSupplierName] = useState('')
const [priceListId,setPriceListId] = useState('')
const [istaxincludedFlag,setIstaxincludedFlag] = useState('')
const [deliveryLocationList,setDeliveryLocationList] = useState([])
const [regionName,setRegionName] = useState('')
const [deliveryLocationName,setDeliveryLocationName] = useState('')
const [loading,setLoading] = useState(false)
const [productData,setProductData] = useState([])
const [selectedProductObject,setSelectedProductObject] = useState({})
const [disableDiscountInput,setdisableDiscountInput] = useState('')
const [supplierAddressName,setSupplierAddressName] = useState('')
const [supplierAddressId,setSupplierAddressId] = useState('')
const [otherCostData,setOtherCostData] = useState([])
const [addCostVisible,setAddCostVisible] = useState(false)
const [landedCostDropDownData ,setLandedCostDropDownData] = useState([])
const [selectedLandedCost,setSelectedLandedCost] = useState({})
const [scheduledDate,setScheduledDate] = useState("")
const [poSummaryData,setPoSummaryData] = useState([])
const [poSummaryVisible,setPoSummaryVisible] = useState(false)
const [tabKey,setTabKey] = useState('10')
const [importModalVisible,setImportModalVisible] = useState(false)
const [importDiscountType,setImportDiscountType] = useState('')

const [form] = Form.useForm();
const [headerform] = Form.useForm()
const [otherCostForm] = Form.useForm()
const [summaryForm] = Form.useForm()
const [importform] = Form.useForm()

const getBusinessUnit = async () =>{
  const businessUnitResponse = await getCustomBusinessUnit()
  setBunitData(businessUnitResponse)
}

const getSuppliers = async () =>{
  const supplierResponse = await getSupplierData()
  setSupplierData(supplierResponse)
}

const onSelectBusinessUnit = (e,data) =>{
  const { title } = data.props
  const date = new Date()
  const minDate = date.toISOString().slice(0, 10)
  setBunitId(e)
  setBusinessUnitName(title)
  setCurrentdate(minDate)
  setScheduledDate(minDate)
  getDeliverLocation(e)
}

const getDeliverLocation = async (e) =>{
  const deliveryResponse = await getDeliveryLocation(e)
  setDeliveryLocationList(deliveryResponse)
  setRegionName(deliveryResponse[0].bUnitLocationId)
}

const onSelectSupplier = (e, data) =>{
  const date = new Date()
  const minDate = date.toISOString().slice(0, 10)
  setSupplierId(e)
  setSupplierName(data.props.title)
  setCurrentdate(minDate)
  setPriceListId(data.props.pricelistid)
  setIstaxincludedFlag(data.props.istaxflag)
}

const onSelectDeliveryLocation = async (e,data)=>{
setDeliveryLocationName(data.props.title)
setLoading(true)
const supplierProductResponse = await getSupplierProduct(supplierId,bunitId)
setProductData(supplierProductResponse)
setLoading(false)
const supplierAddressResponse = await getSupplierAddress(supplierId)
setSupplierAddressName(supplierAddressResponse[0].name === undefined ? null : supplierAddressResponse[0].name)
setSupplierAddressId(supplierAddressResponse[0].recordid)
}

const getLandedCost = async () =>{
  const getLandedCostResponse = await getLandedCostData()
  setLandedCostDropDownData(getLandedCostResponse)
}

const getSelectedRecord = (data) =>{
  setSelectedProductObject(data)
  const disabledFlagg = data.discountType === '' || data.discountType === null || data.discountType === undefined ? false :true
  setdisableDiscountInput(disabledFlagg)
  form.setFieldsValue({
    'skuValue':data.value,
    'productName':data.name,
    'productCategoryName':data.productCategoryName,
    'uomName':data.uomName,
    'HSNCode':"",
    'UPC':data.upc,
    'orderQty':data.orderQty,
    'freeqty':data.freeqty,
    'discountType':data.discountType,
    'discount':data.discount,
    'BasePriceChange':data.priceStd1,
    'unitPrice':data.priceStd,
    'netUnitPrice':data.netUnitPrice,
    'priceList':data.priceList,
    'taxName':data.taxName,
    'margin':(((data.priceList - data.priceStd) / data.priceList) * 100).toFixed(2),
    'oldMargin':(((data.priceList1 - data.priceStd2) / data.priceList1) * 100).toFixed(2),
    'grossAmount':data.grossAmount,
    'totalDiscount':data.totalDiscount,
    'twoWeekSale':data.twoWeekSale,
    'qtyOnHand':data.qtyOnHand,
    'description':data.description
  })
}

const addCostCancel = () =>{
  setAddCostVisible(false)
}

const onSelectLandedCost = (e,data) =>{
  setSelectedLandedCost(data.props.data)
}
const addCostToGrid = () =>{
  const formFieldsData = otherCostForm.getFieldsValue(true);
  const uniqueId = uuid()
      .replace(/-/g, '')
      .toUpperCase()
  const costObj = {
    id : uniqueId,
    calcMethod:selectedLandedCost.calcMethod,
    name:selectedLandedCost.name,
    mProductId:selectedLandedCost.product.mProductId,
    costPrice:formFieldsData.costEntered,
    csTaxId:selectedLandedCost.csTaxId,
    pLcTypeId:selectedLandedCost.pLcTypeId
  }
  setOtherCostData([...otherCostData,costObj])
  setSelectedLandedCost([])
  setAddCostVisible(false)
  otherCostForm.resetFields();
}

const openCostModal = () =>{
  setAddCostVisible(true)
}

const onFinish = () =>{

}

const OnChangeOrderQty = () =>{
  const productObject = selectedProductObject
  const formFieldsData = form.getFieldsValue(true);
const discountType = formFieldsData.discountType
let description = formFieldsData.description
let freeqty = formFieldsData.freeqty === null || formFieldsData.freeqty === undefined || formFieldsData.freeqty === "" ? 0 : formFieldsData.freeqty
let price=0;
let dprice = formFieldsData.discount === null || formFieldsData.discount === undefined || formFieldsData.discount === "" ? 0 : formFieldsData.discount;
let orQty = formFieldsData.orderQty === null || formFieldsData.orderQty === undefined  ? 0:formFieldsData.orderQty
let d3;
let d4;
let margin;
let gridGrossAmt; 
let totalDiscount =0;
let istaxincluded = istaxincludedFlag
let unitPrice;
let netUnitPrice;
let totalTax;
const basePrice = formFieldsData.BasePriceChange
d3 = dprice/orQty
d4 = isNaN(d3)
if(d4 === true){
  price= 0
}else{
  price = d3
}
if(istaxincluded === 'Y'){
  if(discountType === undefined){
    setdisableDiscountInput(false)
    const netUnitPrice1 = ((basePrice/(1+productObject.taxRate/100)) - price)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
    const gridGrossAmt1 = (unitPrice1 * orQty)
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }else if(discountType === "Total value Discount"){
    setdisableDiscountInput(true)
    const netUnitPrice1 = ((basePrice/(1+productObject.taxRate/100)) - price)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const  unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
    const  gridGrossAmt1 = (unitPrice1 * orQty)
    const  margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const basePriceAfterTax = (basePrice/(1+productObject.taxRate/100))
    const  totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
    const  totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }else if(discountType === "Percentage"){
    setdisableDiscountInput(true)
    const costPrice3 = (basePrice/(1+(productObject.taxRate/100)))
    const discountAmount = ((dprice / 100) * costPrice3)
    const netUnitPrice1 = ((basePrice/(1+productObject.taxRate/100)) - discountAmount)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
    const gridGrossAmt1 = (unitPrice1 * orQty).toFixed(2)
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const basePriceAfterTax = (basePrice/(1+productObject.taxRate/100))
    const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }else if(discountType === "Value"){
    setdisableDiscountInput(true)
    const netUnitPrice1 = ((basePrice/(1+productObject.taxRate/100)) - dprice)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
    const gridGrossAmt1 = unitPrice1 * orQty
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const basePriceAfterTax = (basePrice/(1+productObject.taxRate/100))
    const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }
}else{
  if(discountType === undefined){
    setdisableDiscountInput(false)
    const netUnitPrice1 = (basePrice - price)
    const taxOnUnitPrice1 = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice1)
    const gridGrossAmt1 = (unitPrice1 * orQty)
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }else if(discountType === "Total value Discount"){
    setdisableDiscountInput(true)
    const netUnitPrice1 = (basePrice - price)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
    const gridGrossAmt1 = (unitPrice1 * orQty)
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }else if(discountType === "Percentage"){
    setdisableDiscountInput(true)
    const discountAmount = ((dprice / 100) * basePrice)
    const netUnitPrice1 = (basePrice - discountAmount)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
    const gridGrossAmt1 = (unitPrice1 * orQty)
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }else if(discountType === "Value"){
    setdisableDiscountInput(true)
    const netUnitPrice1 = (basePrice - dprice)
    const taxOnUnitPrice = ((productObject.taxRate/ 100) * netUnitPrice1)
    const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
    const gridGrossAmt1 = unitPrice1 * orQty
    const margin1 = (((productObject.priceList - unitPrice1) / productObject.priceList) * 100)
    const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
    const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
    totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
  }
}
form.setFieldsValue({
  'grossAmount':gridGrossAmt,
  'totalDiscount':totalDiscount,
  'unitPrice':unitPrice,
  'margin':margin,
  'netUnitPrice':netUnitPrice,
})

const obj = {
  'totalTax':totalTax,
  'discountType':discountType,
  'discount':dprice,
  'freeqty':freeqty,
  'description': description,
  'margin': margin,
  'oldMargin' :(((productObject.priceList - productObject.priceStd2) / productObject.priceList) * 100).toFixed(2),
  'orderQty':orQty,
  'name': productObject.name,
  'netUnitPrice': netUnitPrice,
  'priceList': productObject.priceList,
  'priceList1': productObject.priceList,
  'priceStd': unitPrice,
  'priceStd1': basePrice,
  'priceStd2': productObject.priceStd2,
  'productCategoryName': productObject.productCategoryName,
  'productId': productObject.productId,
  'qtyOnHand': productObject.qtyOnHand,
  'responseMargin': productObject.responseMargin,
  'taxId': productObject.taxId,
  'taxName': productObject.taxName,
  'taxRate': productObject.taxRate,
  'twoWeekSale': productObject.twoWeekSale,
  'unitPrice': unitPrice,
  'unitPrice1': productObject.unitPrice1,
  'uomId': productObject.uomId,
  'uomName': productObject.uomName,
  'upc': productObject.upc,
  'value': productObject.value,
  'totalDiscount':totalDiscount,
  'grossAmount':gridGrossAmt,
}
const newArray = []
for (let index = 0; index < productData.length; index++) {
  const productIdFromArray = productData[index].productId;
  if(productIdFromArray !==productObject.productId){
    newArray.push(productData[index])
  }
}
newArray.unshift(obj)
setProductData(newArray)
}

const callbackTabs = (key) =>{
  setTabKey(key)
  form.resetFields()  
    summaryForm.setFieldsValue({
      'summreceiptdate':moment(scheduledDate,dateFormat),
      'summbusinessunit':businessUnitName,
      'summorderdate':currentDate,
      'summsupplier':supplierName,
      'summsupplierAddress':supplierAddressName,
      'summdeliveryAddress':deliveryLocationName,
    })
  let newArray =[]
  for (let index = 0; index < productData.length; index++) {
    const element = productData[index].orderQty
      const ele2 = productData[index].freeqty
      if (element > 0 || ele2 > 0) {
        productData[index].freeqty = productData[index].freeqty === "" || null ? 0 : productData[index].freeqty
        const taxRate = productData[index].taxRate
        const basePrice = productData[index].priceStd2
        let grossStd;
        let netStd;
        if(istaxincludedFlag === 'Y'){
          grossStd = productData[index].priceStd2
          netStd = (basePrice/(1+taxRate/100)).toFixed(2)
        }else{
          const taxOnUnitPrice = ((taxRate/ 100) * basePrice)
          grossStd = (parseFloat(basePrice) + parseFloat(taxOnUnitPrice)).toFixed(2)
          netStd = productData[index].priceStd2
        }
        productData[index].grossStd = grossStd
        productData[index].netStd = netStd
        newArray.push(productData[index])
      }
  }
  setPoSummaryData(newArray)
}
const poReview = () =>{
  setTabKey("13")
  summaryForm.setFieldsValue({
    'summreceiptdate':moment(scheduledDate,dateFormat),
    'summbusinessunit':businessUnitName,
    'summorderdate':currentDate,
    'summsupplier':supplierName,
    'summsupplierAddress':supplierAddressName,
    'summdeliveryAddress':deliveryLocationName,
  })
  let newArray =[]
  for (let index = 0; index < productData.length; index++) {
    const element = productData[index].orderQty
      const ele2 = productData[index].freeqty
      if (element > 0 || ele2 > 0) {
        productData[index].freeqty = productData[index].freeqty === "" || null ? 0 : productData[index].freeqty
        const taxRate = productData[index].taxRate
        const basePrice = productData[index].priceStd2
        let grossStd;
        let netStd;
        if(istaxincludedFlag === 'Y'){
          grossStd = productData[index].priceStd2
          netStd = (basePrice/(1+taxRate/100)).toFixed(2)
        }else{
          const taxOnUnitPrice = ((taxRate/ 100) * basePrice)
          grossStd = (parseFloat(basePrice) + parseFloat(taxOnUnitPrice)).toFixed(2)
          netStd = productData[index].priceStd2
        }
        productData[index].grossStd = grossStd
        productData[index].netStd = netStd
        newArray.push(productData[index])
      }
  }
  setPoSummaryData(newArray)
  const countOfProducts = newArray.length
  if(countOfProducts === 0){
    message.error('Please Add Products')
  }else{
    let lessMarginSkus =[]
       for (let index45 = 0; index45 < newArray.length; index45++) {
         const margin = parseInt(newArray[index45].margin);
         const oldMargin = parseInt(newArray[index45].oldMargin)
         if(margin >= oldMargin){
         }else{
          lessMarginSkus.push(newArray[index45].value)
         }
       }
       if(lessMarginSkus.length > 0){
        Modal.error({
          title: 'Purchase Margin less than old Margin !',
          content: `${lessMarginSkus}`,
          closable: true,
          footer: null,
        })
    }else{
      setPoSummaryVisible(true)
    }
  }
}

const getPrintCofirmation = (recordId) => {
  Modal.confirm({
    title: 'Purchase Order Created Successfully',
    content: 'Do you want take Printout',
    okText: 'Yes',
    cancelText: 'No',
    onOk() {
      getPrintPdffile(recordId)
      setPoSummaryData([])
      setProductData([])
      setOtherCostData([])
    },
    onCancel() {
      setPoSummaryVisible(false)
      setProductData([])
      setPoSummaryData([])
      setProductData([])
      setOtherCostData([])
    },
  });
}

const getPrintPdffile = (recordId) => {
  const newToken = JSON.parse(localStorage.getItem("authTokens"));
  const RoleId = window.localStorage.getItem('userData')
  const getPrintPdfData = {
    query: `query {reportTemplate(ad_tab_id:"EE797F0AD47E41A08CFBC7867F538661",recordId:"${recordId}")}`,
  }

  Axios({
    url: genericUrl,
    method: 'POST',
    async: true,
    crossDomain: true,
    data: getPrintPdfData,

    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${newToken.access_token}`,
      RoleId: `${RoleId.role_id}`,
    },
  }).then(response => {
    if (
      response.data.data.reportTemplate === null ||
      response.data.data.reportTemplate === 'null' ||
      response.data.data.reportTemplate === ''
    ) {
      
    } else {
      getPrintCommand(response.data.data.reportTemplate)
    }
  })
}

const getPrintCommand = fileName => {
  setProductData([])
  setPoSummaryVisible(false)
  Axios({
    url: `${fileDownloadUrl}`.concat(`${fileName}`),
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.setAttribute('id', 'downloadlink')
    link.href = fileURL
    link.setAttribute('download', `${fileName}`)
    link.click()
  })
}

const createPO = () =>{
  setLoading(true)
  const newToken = JSON.parse(localStorage.getItem("authTokens"));
  const formFieldsData = summaryForm.getFieldsValue(true);
  const Remarks = formFieldsData.summremarks
  const arrayForMutation = []
    const arrayForLandedCost = []
    let uomId = ''
    let productId1 = ''
    let orderQty1 = ''
    let unitPrice = ''
    let listPrice = ''
    let taxId = ''
    let description1 = ''
    let freeqty = ''
    let margin = ''
    let discountValue=''
    let discountType=''
    let totalDiscount=''
    let PLcTypeId=''
    let Value=''
    let MProductId =''
    let CsTaxId=''
    let grossStd
    let netStd
  for (let index2 = 0; index2 < otherCostData.length; index2++) {
    PLcTypeId = otherCostData[index2].pLcTypeId
    Value = otherCostData[index2].costPrice
    MProductId = otherCostData[index2].mProductId
    CsTaxId = otherCostData[index2].csTaxId
    arrayForLandedCost.push(
      `{
        pLcTypeId : "${PLcTypeId}",
        value :  ${Value},      
        productId : "${MProductId}",
        taxId : "${CsTaxId}"
      }`,
    )
  }
  for (let index = 0; index < poSummaryData.length; index++) {
  uomId = poSummaryData[index].uomId
  grossStd = poSummaryData[index].grossStd
  netStd = poSummaryData[index].netStd
  productId1 = poSummaryData[index].productId
  orderQty1 = poSummaryData[index].orderQty
  unitPrice = poSummaryData[index].priceStd
  listPrice = poSummaryData[index].priceList
  taxId = poSummaryData[index].taxId
  description1 =
  poSummaryData[index].description === null ||
  poSummaryData[index].description === undefined ||
  poSummaryData[index].description === 'null'
      ? ''
      : poSummaryData[index].description
  freeqty = poSummaryData[index].freeqty === undefined ? 0 : poSummaryData[index].freeqty === null ? 0: poSummaryData[index].freeqty === "" ? 0 : poSummaryData[index].freeqty
  margin = poSummaryData[index].margin
  discountValue = poSummaryData[index].discount === null || poSummaryData[index].discount === undefined ? 0 : poSummaryData[index].discount    
  discountType = poSummaryData[index].discountType === "Total value Discount" ? 'TV' : poSummaryData[index].discountType === "Percentage" ? 'P' :poSummaryData[index].discountType === "Value" ? 'V':null
  totalDiscount = poSummaryData[index].totalDiscount
  arrayForMutation.push(
    `{
      
        productId : "${productId1}",
        uomId : "${uomId}",
        orderQty : ${orderQty1},
        unitPrice : ${unitPrice},                                      
        listPrice : ${listPrice},
        taxId : "${taxId}",
        description : "${description1}",
        freeqty : ${freeqty},
        margin : ${margin},
        discountvalue : ${discountValue},
        discountType: "${discountType}",       
        totalDiscount: "${totalDiscount}",
        grossstd:${grossStd},
        netstd:${netStd}
    }`,
  )
}
const createPoOrder = {
  query: `mutation {
    createPO(order : {
            bunitId : "${bunitId}",
            bunitLocationId : "${regionName}",
            supplierId : "${supplierId}",
            dateOrdered : "${currentDate}",
            datePromised : "${scheduledDate}",
            isTaxIncluded : "${istaxincludedFlag}",
            pricelistId : "${priceListId}",
            description : "${Remarks === undefined || null ? null : Remarks}",
            deliveryNote : "Check Quantity & Price",
            supplierAddressId : "${supplierAddressId}",
            lines : [${arrayForMutation}]
            landedCost:[${arrayForLandedCost}]
    }) {
            type
            code
            message
            documentNo
            extraParam
    }
}`,
}
Axios({
  url: serverUrl,
  method: 'POST',
  data: createPoOrder,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${newToken.access_token}`,
  },
}).then(response => {
  if (response.data.data !== null) {
    const Status = response.data.data.createPO.type
    const messageForSuccess = response.data.data.createPO.message
    if (Status === 'Success') {
      setPoSummaryVisible(false)
      const recordId = response.data.data.createPO.extraParam  
      form.resetFields();
      summaryForm.resetFields([
      "summbusinessunit",
      "summsupplier",
      "summsupplierAddress",
      "summdeliveryAddress",
      ]);
      headerform.resetFields();
      setBusinessUnitName('')
      setSupplierName('')
      setSupplierAddressName('')
      setDeliveryLocationName('')
      setLoading(false)
      getPrintCofirmation(recordId)
    }else{
      message.error(messageForSuccess)
      setLoading(false)
    }
  }else{
    message.error('getting error while creating a PO')
    setLoading(false)
  }
})
}

// Import //

const getImportModal = () =>{
  setImportModalVisible(true)
}

const importModalClose = () =>{
  setImportModalVisible(false)
  importform.resetFields()
  document.getElementById('choosefile').value = null
}

const onSelectDiscountType = (e) =>{
  setImportDiscountType(e)
}
//Import End //

const createPoCancel = () =>{
  setPoSummaryVisible(false)
}
const disabledPreviousDate = current =>{
  return current && current < moment().startOf('day');
}

const readFileData = (evt) => {
  const newToken = JSON.parse(localStorage.getItem("authTokens"));
  const { files } = evt.target;
  const filename = files[0].name;
  const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
  if (fileExt !== ".csv") {
    message.error("Please select csv file");
  } else {
    const file = files[0];
    const reader = new FileReader();
    let fileTextData;
    reader.onload = (e) => {
      fileTextData = e.target.result;
      const rows = fileTextData.split(/\r?\n/);
      var dataArr = []
        for (var i = 1; i < rows.length - 1; i++) {
          var cells = rows[i].split(',')
          if (cells.length === 5) {
            dataArr.push({
              sku: cells[0],
              price: cells[1],
              qty: cells[2],
              freeqty: cells[3],
              discount: cells[4],
            })
          }
        }
        let flag = true;
        for (let index = 0; index < dataArr.length; index++) {
          const element = parseInt(dataArr[index].discount);
          if(element > 0 && importDiscountType === '' ){
            flag = false
          }else{
            flag = true
          }
        }
        if(flag === false){
          message.error("Please Select discout type")
          document.getElementById('choosefile').value = null
        }else{
          if (dataArr.length > 0) {
            const matchedArray = []
            const unmatchedArray = []

            for (let indexOne = 0; indexOne < dataArr.length; indexOne += 1) {
              let boolean = true
              for (let indexTwo = 0; indexTwo < productData.length; indexTwo += 1) {
                if (dataArr[indexOne].sku === productData[indexTwo].value) {
                  matchedArray.push(productData[indexTwo])
                  boolean = false
                }
              }
              if (boolean) {
                unmatchedArray.push(dataArr[indexOne].sku)
              }
            }
            const tempArray = []
            if(unmatchedArray.length > 0){
              const skuData = JSON.stringify(unmatchedArray)
              const getProducts = {
                query: `query {
                getPurchaseProduct (bunitId : "${bunitId}", supplierId : "${supplierId}",
                product : null,
                productCodes:${[skuData]}){
                clientId
                bunitId
                productId
                value
                name
                upc
                description
                qtyOnHand
                uomId
                uomName
                productCategoryId
                productCategoryName
                taxCategoryId
                taxCategoryName
                taxId
                taxName
                taxRate
                priceStd
                priceList
                twoWeekSale
                fourWeekSale
                isTaxIncluded
                alternateUomList {
                    alternateUomId uomId uomName
                }
                margin
            }    
            }`,
              }
              Axios({
                url: serverUrl,
                method: 'POST',
                data: getProducts,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `bearer ${newToken.access_token}`,
                },
              }).then(response => {
                const getPurchaseData = response.data.data.getPurchaseProduct
                const array3 = [...getPurchaseData, ...matchedArray]
                for (let index8 = 0; index8 < array3.length; index8 += 1) {
                  const obj = {
                    productCategoryName:
                    array3[index8].productcategoryName || array3[index8].productCategoryName,
                    name: array3[index8].productName || array3[index8].name,
                    description: array3[index8].description,
                    twoWeekSale: array3[index8].twoWeekSale,
                    qtyOnHand: array3[index8].qtyOnHand,
                    productId: array3[index8].productId,
                    uomName: array3[index8].uomName,
                    uomId: array3[index8].uomId,
                    priceList: array3[index8].priceList,
                    priceStd: array3[index8].priceStd,
                    taxId: array3[index8].taxId,
                    value: array3[index8].productCode || array3[index8].value,
                    responseMargin:array3[index8].margin,
                    unitPrice: array3[index8].priceStd,
                    netUnitPrice: array3[index8].priceStd,
                    unitPrice1: array3[index8].priceStd,
                    priceList1: array3[index8].priceList,
                    priceStd1: array3[index8].priceStd,
                    priceStd2: array3[index8].priceStd,
                    oldMargin : (((array3[index8].priceList - array3[index8].priceStd) / array3[index8].priceList) * 100).toFixed(2),
                    upc :array3[index8].upc,
                    taxName:array3[index8].taxName,
                    taxRate:array3[index8].taxRate,
                    istaxincluded:array3[index8].istaxincluded || array3[index8].isTaxIncluded, 
                  }
                  tempArray.push(obj)
                }
                message.success(`${tempArray.length} products imported ...`)
                for (
                  let tempArrayindex = 0;
                  tempArrayindex < tempArray.length;
                  tempArrayindex += 1
                ) {
                  const skus =
                    tempArray[tempArrayindex].productCode || tempArray[tempArrayindex].value
                  for (let index11 = 0; index11 < dataArr.length; index11 += 1) {
                    if (dataArr[index11].sku === skus) {
                      tempArray[tempArrayindex].orderQty = dataArr[index11].qty
                      tempArray[tempArrayindex].freeqty = dataArr[index11].freeqty === null || dataArr[index11].freeqty === undefined || dataArr[index11].freeqty === "" || dataArr[index11].freeqty === "\r" ? null : dataArr[index11].freeqty
                      tempArray[tempArrayindex].discount = dataArr[index11].discount === null || dataArr[index11].discount === undefined || dataArr[index11].discount === "" || dataArr[index11].discount === "\r" ? 0 : dataArr[index11].discount
                    }
                  }
                }
                let finalunMatchedArray = []
                for (let index9 = 0; index9 < dataArr.length; index9 += 1) {
                  const element9 = dataArr[index9].sku
                  let boolean = true
                  for (let index10 = 0; index10 < tempArray.length; index10 += 1) {
                    const element10 = tempArray[index10].productCode || tempArray[index10].value
                    if (element9 === element10) {
                      boolean = false
                    }
                  }
                  if (boolean) {
                    finalunMatchedArray.push(dataArr[index9].sku)
                  }
                }
                if (finalunMatchedArray.length > 0) {
                  setTimeout(() => {
                    Modal.error({
                      title: 'Products not found !',
                      content: `${finalunMatchedArray}`,
                      closable: true,
                      footer: null,
                    })
                  }, 2000)
                }
              })
            }else{
              let allMatchedFinalTempArray = []
              for (let index12 = 0; index12 < matchedArray.length; index12 += 1) {
                const obj = {
                  productCategoryName:
                  matchedArray[index12].productcategoryName ||
                  matchedArray[index12].productCategoryName,
                  name: matchedArray[index12].productName || matchedArray[index12].name,
                  description: matchedArray[index12].description,
                  twoWeekSale: matchedArray[index12].twoWeekSale,
                  qtyOnHand: matchedArray[index12].qtyOnHand,
                  productId: matchedArray[index12].productId,
                  uomName: matchedArray[index12].uomName,
                  uomId: matchedArray[index12].uomId,
                  priceList: matchedArray[index12].priceList,
                  priceStd: matchedArray[index12].priceStd,
                  taxId: matchedArray[index12].taxId,
                  value: matchedArray[index12].productCode || matchedArray[index12].value,
                  responseMargin:matchedArray[index12].margin,
                  unitPrice: matchedArray[index12].priceStd,
                  netUnitPrice: matchedArray[index12].priceStd,
                  unitPrice1: matchedArray[index12].priceStd,
                  priceList1: matchedArray[index12].priceList,
                  priceStd1: matchedArray[index12].priceStd,
                  priceStd2: matchedArray[index12].priceStd,
                  oldMargin : (((matchedArray[index12].priceList - matchedArray[index12].priceStd) / matchedArray[index12].priceList) * 100).toFixed(2),
                  upc :matchedArray[index12].upc,
                  taxName:matchedArray[index12].taxName,
                  taxRate:matchedArray[index12].taxRate,
                  istaxincluded:matchedArray[index12].istaxincluded || matchedArray[index12].isTaxIncluded, 
                }
                allMatchedFinalTempArray.push(obj)
              }

              for (
                let tempArrayindex2 = 0;
                tempArrayindex2 < allMatchedFinalTempArray.length;
                tempArrayindex2 += 1
              ) {
                const skus =
                  allMatchedFinalTempArray[tempArrayindex2].productCode ||
                  allMatchedFinalTempArray[tempArrayindex2].value
                for (let index14 = 0; index14 < dataArr.length; index14 += 1) {
                  if (dataArr[index14].sku === skus) {
                    allMatchedFinalTempArray[tempArrayindex2].orderQty = dataArr[index14].qty
                    allMatchedFinalTempArray[tempArrayindex2].freeqty = dataArr[index14].freeqty === null || dataArr[index14].freeqty === undefined || dataArr[index14].freeqty === "" || dataArr[index14].freeqty === "\r" ? null : dataArr[index14].freeqty
                    allMatchedFinalTempArray[tempArrayindex2].discount = dataArr[index14].discount === null || dataArr[index14].discount === undefined || dataArr[index14].discount === "" || dataArr[index14].discount === "\r" ? 0 : dataArr[index14].discount
                    tempArray.push(allMatchedFinalTempArray[tempArrayindex2])
                  }
                }
              }
              message.success(`${allMatchedFinalTempArray.length} products imported ...`)
            }
            setTimeout(() => {
              getDiscountCalculation(tempArray)
            }, 3000);
          }else{
          message.error('Please import the file in required format.')
          document.getElementById('choosefile').value = null
          }
        }
    };

    reader.readAsText(file);
  }
}

const getDiscountCalculation = (tempArray) =>{
  const multipleProductData = tempArray
  const newArray=[] 
  if(istaxincludedFlag === 'Y'){
    if(importDiscountType === undefined){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        const d3 = discount/orQty
        let price;
        const d4 = isNaN(d3)
            if(d4 === true){
              price= 0
            }else{
              price = d3
            }
        const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - price)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 =( unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const basePriceAfterTax = (basePrice/(1+taxRate/100))
        const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = null
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount=totalDiscount
        newArray.push(multipleProductData[index])
      }
    }
    else if(importDiscountType === 'Total value Discount'){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        const d3 = discount/orQty
        let price;
        const d4 = isNaN(d3)
            if(d4 === true){
              price= 0
            }else{
              price = d3
            }
        const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - price)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 =( unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const basePriceAfterTax = (basePrice/(1+taxRate/100))
        const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount=totalDiscount
        newArray.push(multipleProductData[index])
      }
    }else if(importDiscountType === 'Percentage'){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        
        const costPrice3 = (basePrice/(1+(taxRate/100)))
        const discountAmount = ((discount / 100) * costPrice3)

        const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - discountAmount)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 = (unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const basePriceAfterTax = (basePrice/(1+taxRate/100))
        const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)

        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount = totalDiscount
        newArray.push(multipleProductData[index])
      }

    }else if(importDiscountType ==='Value'){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList

        const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - discount)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 = (unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const basePriceAfterTax = (basePrice/(1+taxRate/100))
        const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)

        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount = totalDiscount
        newArray.push(multipleProductData[index])
      }
    }
  }else{
    if(importDiscountType === undefined){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        const d3 = discount/orQty
        let price;
        const d4 = isNaN(d3)
            if(d4 === true){
              price= 0
            }else{
              price = d3
            }
        const netUnitPrice1 = (basePrice - price)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 = (unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)

        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount = totalDiscount
        newArray.push(multipleProductData[index])
      }
    }
    else if(importDiscountType === 'Total value Discount'){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        const d3 = discount/orQty
        let price;
        const d4 = isNaN(d3)
            if(d4 === true){
              price= 0
            }else{
              price = d3
            }
        const netUnitPrice1 = (basePrice - price)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 = (unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)

        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount = totalDiscount
        newArray.push(multipleProductData[index])
      }
    }else if(importDiscountType === 'Percentage') {
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        
        const discountAmount = ((discount / 100) * basePrice)

        const netUnitPrice1 = (basePrice - discountAmount)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 = (unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)

        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount = totalDiscount
        newArray.push(multipleProductData[index])
      }
    }else if(importDiscountType ==='Value'){
      for (let index = 0; index < multipleProductData.length; index++) {
        const orQty = multipleProductData[index].orderQty
        const discount = multipleProductData[index].discount
        const taxRate = multipleProductData[index].taxRate
        const basePrice = multipleProductData[index].priceStd1
        const MRP = multipleProductData[index].priceList
        
        const netUnitPrice1 = (basePrice - discount)
        const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
        const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
        const gridGrossAmt1 = (unitPrice1 * orQty)
        const margin1 = (((MRP - unitPrice1) / MRP) * 100)
        const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
        const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))

        const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
        const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
        const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
        const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
        const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
        const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)

        multipleProductData[index].totalTax = totalTax
        multipleProductData[index].grossAmount = gridGrossAmt
        multipleProductData[index].discount = discount
        multipleProductData[index].margin = margin
        multipleProductData[index].discountType = importDiscountType
        multipleProductData[index].netUnitPrice = netUnitPrice
        multipleProductData[index].priceStd = unitPrice
        multipleProductData[index].totalDiscount = totalDiscount
        newArray.push(multipleProductData[index])
      }
    }
  }
  let gridArray=[]
  for (let index = 0; index < productData.length; index++) {
    let temp=true
    const finalGridVal = productData[index].value;
    for (let index2 = 0; index2 < newArray.length; index2++) {
      const element = newArray[index2].value;
      if(finalGridVal === element){
        temp=false
        break;
      }
    }
    if(temp){
      gridArray.push(productData[index])
    }
  }
  const finalData = [...newArray,...gridArray]
  setProductData(finalData)
  setImportModalVisible(false)
  setImportDiscountType('')
  importform.resetFields()
  document.getElementById('choosefile').value = null
}
const disabledDateChange = (date, dateString) => {
  setScheduledDate(dateString)
}
    const taxNameArray =[]
    let orderQuantityCount = 0
    let grossAmtCount = 0
    let orderQuantity = 0
    let summaryFreeQty = 0
    let freeQty = 0
    let totalOrderQty = 0
    let totalDiscountAmount = 0
    for (let index = 0; index < poSummaryData.length; index += 1) {
      orderQuantity = poSummaryData[index].orderQty
      summaryFreeQty = poSummaryData[index].freeqty === null || poSummaryData[index].freeqty === undefined || poSummaryData[index].freeqty === "" || poSummaryData[index].freeqty === "\r" ? 0 : poSummaryData[index].freeqty
      const integer = parseInt(orderQuantity, 10)
      orderQuantityCount += integer
      const integer2 = parseInt(summaryFreeQty, 10)
      freeQty += integer2
      const grossAmtWithFloat = poSummaryData[index].grossAmount
      const totalDis = poSummaryData[index].totalDiscount
      grossAmtCount += parseFloat(grossAmtWithFloat) 
      totalDiscountAmount += parseFloat(totalDis)
    }
    totalOrderQty = freeQty + orderQuantityCount
let result = poSummaryData.reduce((c, v) => {
  c[v.taxName] = (c[v.taxName] || 0) + parseFloat(v.totalTax);
  return c;
}, {});

Object.keys(result).map((key) => {
  const taxObj ={
    'taxName':key,
    'taxValue':result[key]
  }
  taxNameArray.push(taxObj)
  return taxObj
});

const summaryDiv =(
  <Card>
    <Form layout="vertical" form={summaryForm} name="summaryForm">
        <Row gutter={16}>
        <Col className="gutter-row" span={8}>
          <Form.Item name="summbusinessunit" label="Business Unit">
            <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
          </Form.Item>
        </Col>
          <Col className="gutter-row" span={8}>
          <Form.Item name="summorderdate" label="Order Date">
            <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
          </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
          <Form.Item name="summreceiptdate" label="Receipt Date">
            <DatePicker  style={{width:'100%'}} disabledDate={disabledPreviousDate} onChange={disabledDateChange} />
          </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
          <Form.Item name="summsupplier" label="Supplier">
            <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
          </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
          <Form.Item name="summsupplierAddress" label="Supplier Address">
            <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
          </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
          <Form.Item name="summdeliveryAddress" label="Delivery Address">
            <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
          </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
          <Form.Item name="summremarks" label="Remarks">
            <Input  style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
          </Form.Item>
          </Col>
        </Row>
    </Form>
    <div>
      <Table
        columns={summaryTableColumns}
        dataSource={poSummaryData}
        style={{ fontSize: "12px" }}
        size="small"
        sticky={true}
        scroll={{ y: "40vh", x: "100%" }}
        pagination={false}
      />
    </div>
    <Row gutter={16}>
      <Col className="gutter-row" span={6}>
      <h4>No. of Products: {poSummaryData.length}</h4>
      </Col>
      <Col className="gutter-row" span={6}>
      <h4>Total Discount: {totalDiscountAmount}</h4>
      </Col>
      <Col className="gutter-row" span={6}>
      <h4>Total Quantity: {totalOrderQty}</h4>
      </Col>
      <Col className="gutter-row" span={6}>
      <h4>Total Amount: {grossAmtCount.toFixed(2)}</h4>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col className="gutter-row" span={6}>
        <Collapse
          bordered={false}
          defaultActiveKey={['2']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="collapseClassStyle"
        >
          <Panel
            header="Delivery Notes"
            key="1"
            showArrow={false}
            className="collapseClassStyle"
          >
            <Input />
          </Panel>
        </Collapse>
      </Col>
      <Col className="gutter-row" span={6}>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="collapseClassStyle"
        >
          <Panel
            header="Terms & Conditions"
            key="2"
            showArrow={false}
            className="collapseClassStyle"
          >
            <Input />
          </Panel>
        </Collapse>
      </Col>
      <Col className="gutter-row" span={6}>
        <span style={{ float: 'right' }}>
          <table style={{border:'1px solid #dddddd'}}>
            <thead>
            <tr>
              <th style={{border:'1px solid #dddddd'}}>Landed Cost</th>
            </tr>
            </thead>
            <tbody>
            {otherCostData.map(data => (
              <tr>
                <td style={{border:'1px solid #dddddd'}}>
                  <span>{data.name}</span> &nbsp;: {data.costPrice}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </span>
      </Col>
      <Col className="gutter-row" span={6}>
        <span>
          <table style={{border:'1px solid #dddddd'}}>
          <thead>
            <tr>
              <th style={{border:'1px solid #dddddd'}}>Tax Breakup</th>
            </tr>
          </thead>
          <tbody>
            {taxNameArray.map(data => (
              <tr>
                <td style={{border:'1px solid #dddddd'}}>
                  <span>{data.taxName}</span> &nbsp;: {(data.taxValue).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          </table>
        </span>
      </Col>
    </Row>
  </Card>
)

    return (
      <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
        <div>
          <Row>
            <Col span={12}>
              <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>New Purchase Order</h2>
            </Col>
            <Col span={12}>
              <span style={{ float: "right" }}>
                <Button onClick={poReview} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>Review</Button>
              </span>
            </Col>
          </Row>
          <Card style={{ marginBottom: "8px" }}>
            <Form layout="vertical" form={headerform} name="control-hooks" onFinish={onFinish}>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="businessUnit" label="Business unit" style={{ marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getBusinessUnit}
                      onSelect={onSelectBusinessUnit}
                    >
                      {bunitData.map((data, index) => (
                        <Option key={data.recordid} value={data.recordid} title={data.name}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="supplier" label="Supplier" style={{ marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onSelect={onSelectSupplier}
                      onFocus={getSuppliers}
                    >
                      {supplierData.map((data, index) => (
                        <Option key={data.recordid} value={data.recordid} title={data.name} istaxflag={data.istaxincluded} pricelistid={data.p_pricelist_id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="deliveryLocation" label="Delivery Location" style={{ marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, Option) => Option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onSelect={onSelectDeliveryLocation}
                    >
                      {deliveryLocationList.map((data, index) => (
                        <Option key={data.bUnitLocationId} value={data.bUnitLocationId} title={data.fulladdress}>
                          {data.fulladdress}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <div>
            <Tabs defaultActiveKey={tabKey}  
            onChange={callbackTabs} 
            type="card" 
            tabBarStyle={{ marginBottom: "0px" }}
            tabBarExtraContent={
              <div>
                <Button size="small" onClick={getImportModal}>
                  Import
                </Button>
              </div>
            }
            >
              <TabPane tab="Products" key="10">
                <Card style={{ marginBottom: "8px" }}>
                  <Form layout="vertical" form={form} name="editable-form" onFinish={onFinish}>
                    <Row gutter={16}>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="skuValue" label="SKU" style={{ marginBottom: "8px" }}>
                          <Select allowClear>
                            {/* <Option value="male">male</Option>
                            <Option value="female">female</Option>
                            <Option value="other">other</Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="productName" label="Name" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="productCategoryName" label="Category" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="uomName" label="UOM" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="HSNCode" label="HSN Code" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="UPC" label="UPC" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="orderQty" label="Order Qty" style={{ marginBottom: "8px" }}>
                          <Input onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="freeqty" label="Free Qty" style={{ marginBottom: "8px" }}>
                          <Input onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="discountType" label="Discount Type" style={{ marginBottom: "8px" }}>
                          <Select allowClear onChange={OnChangeOrderQty}>
                            <Option key="Percentage1" value="Percentage">
                              Percentage
                            </Option>
                            <Option key="Value2" value="Value">
                              Value
                            </Option>
                            <Option key="Totalvd3" value="Total value Discount">
                              Total value Discount
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="discount" label="Discount Value" style={{ marginBottom: "8px" }}>
                          <Input disabled={disableDiscountInput === "" || disableDiscountInput === false ? true : false} onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="BasePriceChange" label="Base Price" style={{ marginBottom: "8px" }}>
                          <Input onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="unitPrice" label="Unit Price" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="netUnitPrice" label="Net Unit Price" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="priceList" label="MRP" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="taxName" label="Tax" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="margin" label="Margin" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="oldMargin" label="Old Margin" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="grossAmount" label="Gross Amount" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="totalDiscount" label="Total Discount" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="twoWeekSale" label="2W/4W sale" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="qtyOnHand" label="On Hand qty" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="description" label="Remarks" style={{ marginBottom: "8px" }}>
                          <Input onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
                <MainTable key="64" gridData={productData} getSelectedRecord={getSelectedRecord} />
              </TabPane>
              <TabPane tab="Landed Cost" key="12">
                <Card>
                  <Row gutter={16} style={{ marginBottom: "8px" }}>
                    <Col className="gutter-row" span={6}>
                      <Input
                        placeholder="Search"
                        style={{ width: "85%" }}
                        suffix={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.contentWindow.recordWindow.linesTab.linesSearchBar.icon} />}
                      />
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Button onClick={openCostModal} style={Themes.contentWindow.recordWindow.linesTab.LinesAddNewButton}>
                        +Add New
                      </Button>
                    </Col>
                  </Row>
                  <OtherCostTable gridData1={otherCostData} />
                </Card>
              </TabPane>
              <TabPane tab="Summary" key="13">
                {summaryDiv}
              </TabPane>
            </Tabs>
          </div>
        </div>
        <div>
        <Modal
          visible={addCostVisible}
          closable={null}
          centered
          width="40%"
          // getContainer={false}
          footer={[
            <Button key="back" onClick={addCostCancel} >
              Cancel
            </Button>,
            <Button
              onClick={addCostToGrid}
            >
              Add
            </Button>,
          ]}
        >
          <h3 style={{ textAlign: "center" }}>
            Other Cost
          </h3>
          <Card  style={{ marginBottom: "0px", border: "none" }}>
              <Form layout="vertical" form={otherCostForm} name="other-cost">
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      label="Cost Name"
                      name="costParameter"
                      key="costParam"
                      rules={[
                        {
                          required: true,
                          message: "please select cost name",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input, Option) => Option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onFocus={getLandedCost}
                        style={{ width: "100%" }}
                        onSelect={onSelectLandedCost}
                      >
                        {landedCostDropDownData.map((data, index) => (
                        <Option key={data.pLcTypeId} data={data} value={data.name}>
                        {data.name}
                        </Option>
                      ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Value" 
                    name="costEntered"
                    key="costVal"
                    rules={[
                      {
                        required: true,
                        message: "please enter cost",
                      },
                    ]}
                    >
                    <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
        </Modal>
        </div>
        <div>
        <Modal
          visible={poSummaryVisible}
          closable={null}
          centered
          width="90%"
          footer={[
            <Button key="back" onClick={createPoCancel} >
              Preview
            </Button>,
            <Button
              loading={loading}
              onClick={createPO}
            >
              Confirm
            </Button>,
          ]}
        >
          <h3 style={{ textAlign: "center" }}>
            Purchase Order Summary
          </h3>
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
          {summaryDiv}
          </Spin>
        </Modal>
        </div>
        <div>
        <Modal
          visible={importModalVisible}
          onCancel={importModalClose}
          closable={null}
          centered
          width="50%"
          footer={[
            <Button
              onClick={importModalClose}
            >
              Close
            </Button>
          ]}
        >
          <h3
            style={{ textAlign: 'center' }}
          >
            Import Products
          </h3>
          <Card>
              <Form layout="vertical" form={importform} name="importform">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="importProductsDiscountType" label="Discount Type">
                        <Select
                          style={{ width: '100%'}}
                          onSelect={onSelectDiscountType}
                        >
                          <Option key="25" value="Percentage">
                            Percentage
                          </Option>
                          <Option key="26" value="Value">
                            Value
                          </Option>
                          <Option key="27" value="Total value Discount">
                            Total value Discount
                          </Option>
                        </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label=" ">
                       <input  id="choosefile" type="file" accept=".csv" onChange={readFileData} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
          </Card>
        </Modal>
        </div>
      </Spin>
    );
}

export default PurchaseOrder