import React, { useState, useEffect, Fragment } from "react";
import { Card, Row, Col, Button, Collapse, Tooltip, Form, Typography, Spin, message, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getTabData, upsertTabData, getWindowInitializationData } from "../../services/generic";
import { FieldReference } from "../../lib/fieldReference";
import RecordForm from "../window/RecordForm";
import StatusBar from "./StatusBar";
import AuditTrail from "../../assets/images/auditTrail.svg";
import Arrow from "../../assets/images/arrow.svg";
import Repeat from "../../assets/images/repeat.svg";
// import Options from "../../assets/images/Options_icon.svg";
// import Filter from "../../assets/images/filter.svg";

import Print from "./Print";
import EmailTemplate from "./EmailTemplate";
import FileAttachment from "./FileAttachment";
import dayjs from "dayjs";
import { useGlobalContext, useWindowContext } from "../../lib/storage";
import { formatDisplayField } from "../window/windowUtilities";
import "antd/dist/antd.css";

const { Panel } = Collapse;
const { Text } = Typography;

const headerOptionIcons = {
  fontSize: "15px",
  fontWeight: "600",
  color: "#536C78",
  paddingBottom: "7px",
  paddingLeft: "4px",
  cursor: "pointer",
};

const customParseFormat = require("dayjs/plugin/customParseFormat");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const RecordHeader = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const { lastRefreshed, setLastRefreshed, setHeaderRecordData, setIsHeaderActive } = props;
  const { recordId } = useParams();
  const [headerTab, setHeaderTab] = useState({ fields: [] });
  const [headerTabId, setHeaderTabId] = useState("");
  const [headerRecord, setHeaderRecord] = useState({});
  const [headerFieldGroups, setHeaderFieldGroups] = useState({});
  const [headerReferenceList, setHeaderReferenceList] = useState([]);
  const [recordTitles, setRecordTitles] = useState([]);
  const [statusBar, setStatusBar] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayAuditInfo, setDisplayAuditInfo] = useState(false);
  const [auditData, setAuditData] = useState({});

  const history = useHistory();

  const { windowStore, setWindowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setLoading(true);
        try {
          if (windowDefinition.tabs) {
            const headerTabData = windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
            const headerTabId = headerTabData.ad_tab_id;
            headerTabData.fields.sort((a, b) => {
              const x = a.seqno !== null ? parseInt(a.seqno) : a.seqno;
              const y = b.seqno !== null ? parseInt(b.seqno) : b.seqno;
              return (x != null ? x : Infinity) - (y != null ? y : Infinity);
            });
            setHeaderTab(headerTabData);
            setHeaderTabId(headerTabId);

            let headerRecordData;
            if (recordId !== "NEW_RECORD") {
              const getTabDataResponse = await getTabData({ ad_tab_id: headerTabData.ad_tab_id, recordId: recordId, startRow: "0", endRow: "1" });
              headerRecordData = getTabDataResponse[0];
            } else {
              setIsEditMode(true);
              headerRecordData = await getWindowInitializationData(headerTabData.ad_tab_id);
            }
            setHeaderRecord(headerRecordData);
            setHeaderRecordData({ ...headerRecordData });
            setWindowStore({ windowHeaderTabRecords: headerRecordData });

            const recordTitle = headerTabData.record_identifier.split(",");
            const recordTitlesData = [];
            recordTitle.forEach((element) => {
              const headerFieldIndex = headerTabData.fields.findIndex((field) => field.ad_field_id === element);
              const recordIdentifierField = headerTabData.fields[headerFieldIndex];
              let titleNameValue = headerRecordData[element.concat("_iden")];
              if (titleNameValue === null || titleNameValue === undefined) {
                titleNameValue = headerRecordData[element];
              }
              titleNameValue = formatDisplayField(titleNameValue, recordIdentifierField);
              recordTitlesData.push({
                titleName: titleNameValue,
                titleTip: titleNameValue,
              });
            });
            setRecordTitles([...recordTitlesData]);

            const statusBarValues = [];
            const referenceList = [];
            const fieldGroupsList = {};
            const auditDataValues = {};
            headerTabData.fields.forEach((element) => {
              if (element["nt_base_reference_id"] === FieldReference.List) {
                const list = element.Values;
                if (list !== undefined || list !== null) {
                  Object.keys(list).forEach((key) => {
                    referenceList.push(list[key]);
                  });
                }
              }

              if (element.isshowninstatusbar === "Y" && element.isdisplayed === "Y" && element.isactive === "Y") {
                let titleDataValue = headerRecordData[element.ad_field_id.concat("_iden")];
                if (titleDataValue === null || titleDataValue === undefined) {
                  titleDataValue = headerRecordData[element.ad_field_id];
                  const refIndex = referenceList.findIndex((list) => list.key === titleDataValue);
                  if (refIndex >= 0) {
                    titleDataValue = referenceList[refIndex].value;
                  }
                }
                if (titleDataValue === "Y") {
                  titleDataValue = "Yes";
                }
                if (titleDataValue === "N") {
                  titleDataValue = "No";
                }
                statusBarValues.push({
                  titleName: element.name,
                  titleValue: titleDataValue,
                });
              }

              if (element.fieldgroup_name !== undefined && element.nt_base_reference_id !== "28") {
                fieldGroupsList[element.fieldgroup_name] = fieldGroupsList[element.fieldgroup_name] || [];
                fieldGroupsList[element.fieldgroup_name].push(element);
              }

              if (element.column_name === "updatedby") {
                auditDataValues.updatedby = headerRecordData[element.ad_field_id.concat("_iden")];
              }
              if (element.column_name === "createdby") {
                auditDataValues.createdby = headerRecordData[element.ad_field_id.concat("_iden")];
              }
              if (element.column_name === "created") {
                auditDataValues.created = dayjs(headerRecordData[element.ad_field_id], "YYYY-MM-DD HH:mm:ss").fromNow();
              }
              if (element.column_name === "updated") {
                auditDataValues.updated = dayjs(headerRecordData[element.ad_field_id], "YYYY-MM-DD HH:mm:ss").fromNow();
              }
            });
            setStatusBar([...statusBarValues]);
            setHeaderFieldGroups(fieldGroupsList);
            setHeaderReferenceList([...referenceList]);
            setAuditData({ ...auditDataValues });
          }
        } catch (error) {
          console.error("Error", error);
        } finally {
          setLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [recordId, lastRefreshed]);

  const updateLastRefresh = () => {
    setLastRefreshed(new Date());
  };

  const getRecordValue = (field) => {
    let recordValueField = headerRecord[field.ad_field_id.concat("_iden")] ? headerRecord[field.ad_field_id.concat("_iden")] : headerRecord[field.ad_field_id];
    const refIndex = headerReferenceList.findIndex((list) => list.key === recordValueField);
    if (refIndex >= 0) {
      recordValueField = headerReferenceList[refIndex].value;
    }
    if (typeof recordValueField === "string") {
      if (recordValueField.trim() === "Y") {
        recordValueField = "Yes";
      }
      if (recordValueField.trim() === "N") {
        recordValueField = "No";
      }
    }
    return formatDisplayField(recordValueField, field, "header");
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    if (isEditMode && recordId !== "NEW_RECORD") {
      setIsEditMode(false);
    } else {
      window.self.close();
      history.push(`/window/list/${windowDefinition.ad_window_id}`);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    Object.entries(values).map(([key, value]) => {
      if (value === true) {
        values[key] = "Y";
      }
      if (value === false) {
        values[key] = "N";
      }
      if (typeof value === "string" && value.search("<p>") > -1) {
        let stripedHtml = value.replaceAll("<p><br></p>", "");
        stripedHtml = stripedHtml.replaceAll("<p>", "<div>");
        stripedHtml = stripedHtml.replaceAll("</p>", "</div>");
        values[key] = stripedHtml;
      }
      if (typeof value === "number") {
        values[key] = `${value}`;
      }
      if (dayjs.isDayjs(value)) {
        values[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (value === "") {
        values[key] = null;
      }
      if (value === undefined) {
        values[key] = null;
      }
      return null;
    });

    if (recordId === "NEW_RECORD") {
      Object.entries(values).map(([ValuesKey, valuesValue]) => {
        Object.entries(headerRecord).map(([headerKey, headerValue]) => {
          if (values[headerKey] === undefined) {
            if (headerKey.search("_iden") === -1) {
              values[headerKey] = headerValue;
            }
          }
          return null;
        });
        return null;
      });
    }

    const stringifiedFields = JSON.stringify(values);
    const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
    const stringRequest = JSON.stringify(updatedStrings);

    upsertTabData(headerTab.ad_tab_id, recordId, stringRequest)
      .then((upsertResponse) => {
        if (upsertResponse.data.data.upsertTab.status === "200") {
          message.success(`${upsertResponse.data.data.upsertTab.message}`);
          setIsEditMode(false);
          if (recordId === "NEW_RECORD") {
            const currentRecord = upsertResponse.data.data.upsertTab.recordId;
            const currentLocation = history.location.pathname;
            const windowType = currentLocation.search("popupWindow") >= 0 ? "popupWindow" : "window";
            history.push(`/${windowType}/${windowDefinition.ad_window_id}/${currentRecord}`);
          } else {
            setLastRefreshed(new Date());
          }
        } else {
          console.error(JSON.stringify(upsertResponse, null, 2));
          // message.error("An Error Occured !");
          // form.setFieldsValue(originalFormValues);
          notification.error({
            message: "Error Processing Operation",
            description: (
              <Collapse ghost>
                <Panel header="Details" key="1">
                  {upsertResponse.data.data.upsertTab.message}
                </Panel>
              </Collapse>
            ),
          });
        }
      })
      .catch((e) => {
        console.error(JSON.stringify(e, null, 2));
        // message.error("An Error Occured !");
        // form.setFieldsValue(originalFormValues);
        /* notification.error({
          message: upsertResponse.data.data.upsertTab.messageCode,
          description: upsertResponse.data.data.upsertTab.message,
        }); */
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsHeaderActive(isEditMode);
  }, [isEditMode]);

  const showPopupWindow = (field) => {
    window.open(`/popupWindow/${field.new_record_window}/${headerRecord[field.ad_field_id]}`, "Record Window", "width=1200,height=600,left=210,top=120");
  };

  const navigateToListWindow = () => {
    window.self.close();
    history.push(`/window/list/${windowDefinition.ad_window_id}`);
  };

  const [collapseAllGroups, setCollapseAllGroups] = useState(false);

  const [form] = Form.useForm();

  const responsiveDesignForAuditTrial = {
    xxl: 10,
    xl: 10,
    lg: 10,
    xs: 10,
    sm: 10,
    md: 10,
  };

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };

  return (
    <Fragment>
      <Row>
        <Col {...responsiveDesignForColumn} style={{ marginTop: "-1.2%", marginBottom: "-0.8%" }}>
          <Button type="link" onClick={navigateToListWindow} style={Themes.contentWindow.recordWindow.RecordHeader.breadCrumb}>
            {windowDefinition.name}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginLeft: "-14px" }}>
          <span style={{ display: "inline-block" }}>
            <div style={Themes.contentWindow.recordWindow.RecordHeader.recordTitleDiv}>
              <span style={Themes.contentWindow.recordWindow.RecordHeader.recordTitle}>
                {recordTitles.map((record, index) => (
                  <Tooltip key={`${index}`} placement="bottom" title={record.titleTip}>
                    <span>&ensp;{record.titleName}</span>
                  </Tooltip>
                ))}
              </span>
            </div>
          </span>
          <span style={{ display: "inline-block", position: "absolute", top: "10px" }}>
            {windowDefinition.enableauditlog === "Y" ? (
              <Tooltip placement="bottom" title="Audit Trail">
                <img onClick={() => setDisplayAuditInfo((a) => !a)} style={headerOptionIcons} src={AuditTrail} alt="AuditTrail" />
              </Tooltip>
            ) : null}
          </span>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col span={14} style={{ maxWidth: "100%", marginTop: "-3px" }}>
          {displayAuditInfo ? (
            <div>
              <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}>
                Created By : <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}> {auditData?.createdby}</span> &emsp;
              </span>
              <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}>
                Created On : <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}> {auditData?.created}</span> &emsp;
              </span>
              <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}>
                Updated By : <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}> {auditData?.updatedby}</span> &emsp;
              </span>
              <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}>
                Updated On : <span style={{ color: "#5D5454", fontSize: "10px", opacity: "77%", marginBottom: "0px" }}> {auditData?.updated}</span> &emsp;
              </span>
            </div>
          ) : null}
        </Col>
        <Col {...responsiveDesignForAuditTrial} style={{ float: "right", paddingTop: "9px" }}>
          <div style={Themes.contentWindow.recordWindow.RecordHeader.iconActionButtonDiv}>
            {windowDefinition.enableedit === "Y" && !isEditMode ? (
              <span>
                <Button style={Themes.contentWindow.recordWindow.RecordHeader.aboveHeaderEditButtons} onClick={handleEdit}>
                  Edit
                </Button>
                <Button style={Themes.contentWindow.recordWindow.RecordHeader.aboveHeaderEditButtons} onClick={handleCancel}>
                  Close
                </Button>
              </span>
            ) : (
              ""
            )}
            {isEditMode ? (
              <span>
                <Button
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        form.submit();
                      })
                      .catch((error) => {
                        setCollapseAllGroups(true);
                        console.error(JSON.stringify(error, null, 2));
                      });
                  }}
                  style={Themes.contentWindow.recordWindow.RecordHeader.aboveHeaderEditButtons}
                >
                  Save
                </Button>
                <Button style={Themes.contentWindow.recordWindow.RecordHeader.aboveHeaderEditButtons} onClick={handleCancel}>
                  Cancel
                </Button>
              </span>
            ) : (
              ""
            )}
            {windowDefinition.enableprint === "Y" ? <Print headerTabId={headerTabId} /> : null}
            {windowDefinition.enableprint === "Y" ? <EmailTemplate headerTabId={headerTabId} /> : null}
            <Tooltip title="Repeat" placement="bottom">
              <Button style={Themes.contentWindow.ListWindowHeader.headerActionButtons} onClick={updateLastRefresh}>
                <img style={{ paddingBottom: "3px", paddingRight: "2px", width: "18px" }} src={Repeat} alt="invoice" />{" "}
              </Button>
            </Tooltip>{" "}
            {/*  <Tooltip title="Options" placement="bottom">
              <Button color="primary" style={Themes.contentWindow.ListWindowHeader.headerActionButtons}>
                <img style={{ paddingBottom: "3px", fontWeight: "600", width: "19px" }} src={Options} alt="invoice" />
              </Button>
            </Tooltip> */}
            {windowDefinition.enableattachment === "Y" || headerTab.tabenabledforimport === "Y" ? <FileAttachment style={{ marginRight: "4px" }} headerTabId={headerTabId} /> : ""}
            {/* {windowDefinition.enablefilter === "Y" ? (
              <Tooltip title="Filter" placement="bottom">
                <Button style={Themes.contentWindow.ListWindowHeader.headerActionButtons}>
                  <img style={{ paddingBottom: "2px", paddingRight: "2px", width: "16px" }} src={Filter} alt="invoice" />{" "}
                </Button>
              </Tooltip>
            ) : (
              ""
            )} */}
          </div>
        </Col>
      </Row>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
        <Card style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} bodyStyle={{ padding: "0px" }}>
          {(() => {
            if (statusBar.length > 0) {
              return <StatusBar statusBar={statusBar} />;
            }
          })()}
          {isEditMode ? (
            <Fragment>
              <RecordForm
                form={form}
                idName="headerTab"
                onFinish={onFinish}
                headerTab={headerTab}
                headerRecord={headerRecord}
                headerFieldGroups={headerFieldGroups}
                recordId={recordId}
                isHeader={true}
                collapseAllGroups={collapseAllGroups}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col span={24} style={Themes.contentWindow.recordWindow.RecordHeader.readOnlyViewColumn}>
                  <Row gutter={[16, 16]}>
                    {headerTab.fields.map((field, index) =>
                      field.isdisplayed === "Y" &&
                      field.fieldgroup_name === undefined &&
                      field.isshowninstatusbar !== "Y" &&
                      field.nt_base_reference_id !== "28" &&
                      field.column_type !== "Button" ? (
                        <Col key={`${index}-${headerRecord[field.ad_field_id]}`} span={field.nt_base_reference_id === FieldReference.WYSIWYGEditor ? 24 : 8}>
                          <Text type="secondary">{field.name}</Text> <br />
                          <Text>
                            {field.new_record_window ? (
                              <span style={{ color: "#1648AA" }}>
                                {getRecordValue(field) ? (
                                  <span style={{ cursor: "pointer" }} onClick={() => showPopupWindow(field)}>
                                    {getRecordValue(field)}&nbsp; <img src={Arrow} style={{ paddingBottom: "5px" }} alt="Arrow" />
                                  </span>
                                ) : null}
                              </span>
                            ) : (
                              getRecordValue(field)
                            )}
                          </Text>
                        </Col>
                      ) : (
                        ""
                      )
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Collapse style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.collapsePanel}>
                    {Object.entries(headerFieldGroups).map(([key, value], i) => (
                      <Panel header={key} key={key}>
                        <Row>
                          <Col span={24} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <Row gutter={[24, 24]}>
                              {value.map((field, index) =>
                                field.isdisplayed === "Y" && field.isshowninstatusbar !== "Y" && field.nt_base_reference_id !== "28" && field.column_type !== "Button" ? (
                                  <Col key={`${index}-${headerRecord[field.ad_field_id]}`} span={field.nt_base_reference_id === FieldReference.WYSIWYGEditor ? 24 : 8}>
                                    <Text type="secondary">{field.name}</Text> <br />
                                    <Text>
                                      {field.new_record_window ? (
                                        <span style={{ color: "#1648AA" }}>
                                          {getRecordValue(field) ? (
                                            <span style={{ cursor: "pointer" }} onClick={() => showPopupWindow(field)}>
                                              {getRecordValue(field)}&nbsp; <img src={Arrow} style={{ paddingBottom: "5px" }} alt="Arrow" />
                                            </span>
                                          ) : null}
                                        </span>
                                      ) : (
                                        getRecordValue(field)
                                      )}
                                    </Text>
                                  </Col>
                                ) : (
                                  ""
                                )
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Panel>
                    ))}
                  </Collapse>
                </Col>
              </Row>
            </Fragment>
          )}
        </Card>
      </Spin>
    </Fragment>
  );
};

export default RecordHeader;
