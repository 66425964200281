import React,{ useState } from "react";
import { Col, Row, Tooltip,Modal,Form,DatePicker,Select } from "antd";
import { InfoCircleOutlined, ExpandAltOutlined, ReloadOutlined, FilterOutlined } from "@ant-design/icons";
import NightMode from "../../assets/images/nightmode.svg";
import {getComboFillForDashboard } from "../../services/generic";
import { genericUrl } from "../../constants/serverConfig";
import Axios from "axios";

const { RangePicker } = DatePicker;

const{Option}=Select
const dateFormat = "YYYY/MM/DD";

const HeaderComponent = (props) => {
  // console.log("====props====",props)
  const {dashboardFilters,isComparableFlag,kpiData,dashboardId,filteredData}=props
  const [filterPopup, setFilterPopup] = useState(false);
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [referenceListDetails, setListReferenceDetails] = useState([]);
  const [form] = Form.useForm();

  const responsiveDesignForColumn = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 12,
    sm: 12,
    md: 12,
  };

  const filterMode = () => {
    console.log("===filtermode==",dashboardFilters)
    setFilterPopup(true)
  };

  const submitFilterDetails=()=>{
    form.submit();
    // setFilterPopup(false)
  }

  const onFinish = async (values) => {
    const valuesArray = Object.values(values)
    const currentYearFromDate = values.fromDate!==undefined?(values.fromDate).format("YYYY-MM-DD"):''
    const currentYearToDate = values.toDate!==undefined?(values.toDate).format("YYYY-MM-DD"):''
    const currentYearDatesArray = []
    currentYearDatesArray.push(currentYearFromDate,currentYearToDate)
    const arrayToSend = {}
    let valuesData = ''
    if (dashboardFilters !== undefined) {
      for (let index = 0; index < dashboardFilters.length; index += 1) {
        for (let index2 = 0; index2 < valuesArray.length; index2++) {
          if (dashboardFilters[index].type === "Date") {
            valuesData = new Date(valuesArray[index2]).toISOString();
          } else if (dashboardFilters[index].type === "DateRange") {
            valuesData = currentYearDatesArray;
            /* if (isComparableFlag === 'Y') {
              arrayToSend[dashboardFilters[index].column_name.concat('_COMPARABLE_')] = previousYearDatesArray
            } */
          } /* else if (dashboardFilters[index].type === 'Flag') {
            valuesData = flagValue
          } */ else {
            valuesData = valuesArray[index2];
          }
        }       
        arrayToSend[dashboardFilters[index].column_name] = valuesData === undefined ? null : valuesData;
      }
      
      for (const [key, value] of Object.entries(arrayToSend)) {
        // console.log("===>key<====",key,"=====>value<=====",value)
        // newData[key] = value
        if (value === null) {
          delete arrayToSend[key];
        }
      }
      const stringifiedJSON = JSON.stringify(arrayToSend);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      for (let index = 0; index < kpiData.length; index++) {
        const element = kpiData[index];
        // setKpiLoading()
        const executeDashboardMutation = {
          query: `query {
          executeDashboard(dashboardId:"${dashboardId}",kpiId:"${element.kpi_id}",dashboardParam:"${jsonToSend}"){data, messageCode, title, message}
        }`,
        };
        Axios.post(genericUrl, executeDashboardMutation, { headers: headers }, { async: true }, { crossDomain: true }).then((execDashRes) => {
          const responseFromServer = execDashRes.data.data.executeDashboard;
          if (responseFromServer.title === "Success") {
            const dashboardData = JSON.parse(responseFromServer.data);
            // console.log("====dashboardData====",dashboardData)
            // props.apply(dashboardData)
            filteredData(dashboardData)
            setFilterPopup(false)
            // dashBoardArr.push(dashboardData)
          } else {
            console.log("======Error Data=====", responseFromServer.message);
          }
        });
      }

    }

  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onDropDownSelect = async (id) => {
    const getDashboardData = await getComboFillForDashboard(id);
    setDropdownDetails([...getDashboardData]);
  };


  const onDropDownSelectForListReference = (values) => {
    console.log("======>values<=====", values);

    setListReferenceDetails([...values]);
  };

  const closeFilterModal = () => {
    setFilterPopup(false)
    form.resetFields();
  };

  const fullScreenMode = () => {
    props.fullScreen(true);
  };

  const nightMode = () => {
    console.log("night");
  };

  const refreshDashboard = () => {
    // console.log("=====refresh Dashboard========",props)
    props.refreshDashboard()
  };

  return (
    <div>
      <Row>
        <Col {...responsiveDesignForColumn} style={{ paddingRight: "2px" }}>
          <span>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>{props.dashboardTitle}</span>&nbsp;
            <span>
              <Tooltip placement="top" title="Demo">
                <InfoCircleOutlined
                  style={{
                    cursor: "pointer",
                    // color: clientConfigurations.dashboardInfoColor,
                    fontSize: "14px",
                    color: "#161537",
                  }} /* onClick={this.openHeaderHelp} */
                />
              </Tooltip>
            </span>
            <span style={{ float: "right" }}>
              <span>
                <FilterOutlined style={{ cursor: "pointer", fontSize: "18px" }} onClick={filterMode} />
              </span>
              &emsp;
              <span>
                <img src={NightMode} style={{ cursor: "pointer", height: "18px", width: "18px", marginBottom: "10px" }} onClick={nightMode} alt="" />
              </span>
              &emsp;
              <span>
                <ExpandAltOutlined style={{ cursor: "pointer", fontSize: "18px", marginBottom: "10px" }} onClick={fullScreenMode} />
              </span>
              &emsp;
              <span>
                <ReloadOutlined
                  style={{
                    cursor: "pointer",
                    // color: sideMenu === 'N' ? clientConfigurations.dashboardInfoColor : '#747474',
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                  onClick={refreshDashboard}
                />
              </span>
            </span>
          </span>
        </Col>
      </Row>
      <Modal title="Manage Filters" visible={filterPopup} onOk={submitFilterDetails} onCancel={closeFilterModal} centered>
        <Row>
          {dashboardFilters !== undefined
            ? dashboardFilters.map((dashboardFilters, index) => {
                return (
                  <Col span={24} key={index} style={{ display: dashboardFilters.is_for_prompting === "Y" ? "block" : "none" }}>
                    <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                      <Form.Item
                        label={dashboardFilters.type==="DateRange"?null:dashboardFilters.display_name}
                        name={dashboardFilters.type==="DateRange"?null:dashboardFilters.column_name}
                        rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                      >
                        {dashboardFilters.type === "MultiSelector" ? (
                          <Select
                            style={{ width: "100%" }}
                            // onSearch={searchDropdownRecords}
                            mode="multiple"
                            maxTagCount={1}
                            showSearch
                            allowClear
                            // notFoundContent={fetching ? <Spin size="small" /> : null}
                            dropdownMatchSelectWidth={false}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onFocus={() => onDropDownSelect(dashboardFilters.filter_id)}
                          >
                            {/* {dropdownList} */}
                            {dropdownDetails === null || dropdownDetails === undefined
                              ? null
                              : dropdownDetails.map((data) => {
                                  // console.log("===>data<====", data);
                                  return (
                                    <Option key={data.recordid} value={data.recordid}>
                                      {data.name}
                                    </Option>
                                  );
                                })}
                          </Select>
                        ) : dashboardFilters.type === "DateRange" ? (
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label="From Date"
                                name="fromDate"
                                rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                              >
                                <DatePicker style={{ width: "90%" }} format={dateFormat} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="To Date"
                                name="toDate"
                                rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                              >
                                <DatePicker style={{ width: "90%" }} format={dateFormat} />
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : dashboardFilters.type === "List" ? (
                          <Select
                            showSearch
                            allowClear
                            // notFoundContent={fetching ? <Spin size="small" /> : null}
                            dropdownMatchSelectWidth={false}
                            style={{ width: "100%" }}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onFocus={() => onDropDownSelectForListReference(dashboardFilters.Values)}
                          >
                            {referenceListDetails === null || referenceListDetails === undefined
                              ? null
                              : referenceListDetails.map((data) => {
                                  // console.log("===>data<====", data);
                                  return (
                                    <Option key={data.key} title={data.key} value={data.key}>
                                      {data.value}
                                    </Option>
                                  );
                                })}
                          </Select>
                        ) : dashboardFilters.type === "Selector" ? (
                          <Select
                            showSearch
                            allowClear
                            // notFoundContent={fetching ? <Spin size="small" /> : null}
                            dropdownMatchSelectWidth={false}
                            style={{ width: "100%" }}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onFocus={() => onDropDownSelect(dashboardFilters.id)}
                          >
                            {dropdownDetails === null || dropdownDetails === undefined
                              ? null
                              : dropdownDetails.map((data) => {
                                  // console.log("===>data<====", data);
                                  return (
                                    <Option key={data.recordid} value={data.recordid}>
                                      {data.name}
                                    </Option>
                                  );
                                })}
                          </Select>
                        ) : dashboardFilters.type === "Date" ? (
                          <DatePicker style={{ width: "100%" }} format={dateFormat} />
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Form>
                    <br />
                  </Col>
                );
              })
            : ""}
        </Row>
        <br />
      </Modal>
    </div>
  );
};

export default HeaderComponent;
