import React,{useState} from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Dropdown, Menu, Card, DatePicker, Form, Select, Table,Spin } from "antd";
import { genericUrl } from "../../../constants/serverConfig.js";
import Axios from "axios";

const dateFormat = "YYYY/MM/DD";
const { Option } = Select;

const SalesGPReport = () => {

  const [gridData, setGridData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalInvoice,setTotalInvoice] = useState(0);
  const [totalLineNet,setTotalLineNetAmt] = useState(0);
  const [totalTrxCost,setTotalTrxCost] = useState(0);
  const [orgDropdownDetails,setOrgDropdownDetails] = useState([]);
  const [form] = Form.useForm();
  
  const handleMenuClick = (obj) => {
    if (obj.key === "view") {
      form.submit();
    } else {
      // form.submit();
      // downloadData();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="view">
        <span>View</span>
      </Menu.Item>
      <Menu.Item key="download">
        <span>Download</span>
      </Menu.Item>
    </Menu>
  );

  const getOrganization = async () => {
    try {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getOrganization = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"61b05acae04aa71f6adf4cd7", params: "{}")}`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      const serverResponse = await Axios.post(genericUrl, getOrganization, { headers: headers }, { async: true }, { crossDomain: true });
      if (serverResponse.status === 200) {
        const organizationData = JSON.parse(serverResponse.data.data.executeAPIBuilder);
        setOrgDropdownDetails([...organizationData]);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getReportDetailsQuery = {
        query: `query {
        getReportJson(reportId:"${values.type}"){data, messageCode, title, message}
        }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      const serverResponse = await Axios.post(genericUrl, getReportDetailsQuery, { headers: headers }, { async: true }, { crossDomain: true });
      const reportJsonResponse = serverResponse.data.data.getReportJson;
      if (reportJsonResponse.messageCode === "200") {
        const reportData = JSON.parse(reportJsonResponse.data);
        const reportFields = reportData.Fields;
        const headerArray = []
        const amountArray = []
        for (let index = 0; index < reportFields.length; index++) {
          const element = reportFields[index];
          if(element.type==="Amount"){
            amountArray.push(element.fieldName)
          }
          headerArray.push({
            // headerName: filtersData[index].displayName,
            title: element.displayName,
            dataIndex: element.fieldName,
            type: element.type,
            key: index+1,
            width: 180,
            ellipsis: true
            /* ellipsis: true,
            render: (text) => (finalArrayToPush[index].drillDown === "Y" ? <a>{text}</a> : text),
            onCell: (record) => ({
              onClick: () => {
                drillDown(jsonToSend, finalArrayToPush[index].fieldName, record[finalArrayToPush[index].fieldName], finalArrayToPush[index].detailReportId);
              },
            }), */
            /* render: (text, record, index) => {
                  console.log("text, record, index",text, record, index)
                } */
          });
        }
        
        const fromDate = new Date(values.fromDate).toISOString();
        const toDate = new Date(values.toDate).toISOString();
        const type = values.type
        const org = values.organization
        const arrayToSend = {};
        
        arrayToSend["DateFrom"]=fromDate
        arrayToSend["DateTo"]=toDate
        arrayToSend["organization"]=org
        arrayToSend["type"]=type

        const stringifiedJSON = JSON.stringify(arrayToSend);
        const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
        const onSubmitQuery = {
            query: `query {
            executeReport(reportId:"${values.type}", reportParam:"${jsonToSend}"){data, messageCode, title, message}
          }`,
        }
        const serverResponse = await Axios.post(genericUrl, onSubmitQuery, { headers: headers }, { async: true }, { crossDomain: true });
        if (serverResponse.status === 200) {        
           const responseForGridData = serverResponse.data.data.executeReport.data;
           const gridData = JSON.parse(responseForGridData);
           // console.log("====headerArray====",headerArray)
           if (gridData.length > 0) {   
            for (let index = 0; index < gridData.length; index++) {
              const element = gridData[index];
              for (let index2 = 0; index2 < amountArray.length; index2++) {
                const element2 = amountArray[index2];
                element[element2] = parseInt(element[element2]);
              }
            }            
          }
           let totalInv = 0;
           let totalLineNet = 0;
           let totalTrxCost = 0;

           gridData.forEach(({ qty_invoiced, linenetamt, trx_cost }) => {
            totalInv += qty_invoiced;
            totalLineNet += linenetamt;
            totalTrxCost += trx_cost
          });
           
           setTotalInvoice(totalInv)
           setTotalLineNetAmt(totalLineNet)
           setTotalTrxCost(totalTrxCost)
           setHeaderData(headerArray)
           setGridData(gridData)
           setLoading(false);

        }

        // setReportFields(reportFields);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
      <Row>
        <Col span={12} style={{ marginBottom: "8px" }}>
          <h2>
            <b>Sales GP Report</b>
          </h2>
        </Col>
        <Col span={12} style={{ marginBottom: "8px" }}>
          <Dropdown.Button style={{ float: "right" }} overlay={menu} type="primary">
            Action
          </Dropdown.Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginBottom: "8px" }}>
          <Card>
            <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Row gutter={8}>
                <Col span={6}>
                  <Form.Item label="From Date" name="fromDate" rules={[{ required: true, message: "Please Enter from date" }]}>
                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="To Date" name="toDate" rules={[{ required: true, message: "Please Enter to date" }]}>
                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Organization" name="organization" rules={[{ required: true, message: "Please select organization" }]}>
                    <Select
                      style={{ width: "100%" }}
                      // onSearch={searchDropdownRecords}
                      // mode="multiple"
                      // maxTagCount={1}
                      showSearch
                      allowClear
                      // notFoundContent={fetching ? <Spin size="small" /> : null}
                      dropdownMatchSelectWidth={false}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={() => getOrganization()}
                    >
                      {orgDropdownDetails === null || orgDropdownDetails === undefined
                        ? null
                        : orgDropdownDetails.map((data) => {
                            // console.log("===>data<====", data);
                            return (
                              <Option key={data.cs_bunit_id} value={data.cs_bunit_id}>
                                {data.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Type" name="type" rules={[{ required: true, message: "Please select type" }]}>
                    <Select
                      style={{ width: "100%" }}
                      // maxTagCount={1}
                      showSearch
                      allowClear
                      dropdownMatchSelectWidth={false}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option key="DEDC22058E174306B2CD1036AC9507CC" value="DEDC22058E174306B2CD1036AC9507CC">
                        By Product Category
                      </Option>
                      <Option key="A55549C05F40488BBB67061050818A38" value="A55549C05F40488BBB67061050818A38">
                        By Product
                      </Option>
                      <Option key="72F2937D916D4F909DEB1DB6870B2310" value="72F2937D916D4F909DEB1DB6870B2310">
                        By Customer
                      </Option>
                      <Option key="BE5EBF5AFE19483D9C2776E4CA215F73" value="BE5EBF5AFE19483D9C2776E4CA215F73">
                        By Customer Group
                      </Option>
                      <Option key="92842554ED244B54B31386A753A76CB0" value="92842554ED244B54B31386A753A76CB0">
                        By Supplier
                      </Option>
                      <Option key="C3477C3DEFB0459889773A78DDB3E23D" value="C3477C3DEFB0459889773A78DDB3E23D">
                        By Brand
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* <Col span={6}>
                <Input />
              </Col>
              <Col span={6}>
                <Input />
              </Col>
              <Col span={6}>
                <Input />
              </Col> */}
              </Row>
              <br />
            </Form>
            <Row>
              <Col span={24} style={{ marginBottom: "8px" }}>
                <Card>
                  <Row gutter={8} style={{ marginLeft: "5%" }}>
                    <Col span={8}>
                      <span>Total Invoice Quantity</span>
                      <br />
                      <b>{totalInvoice}</b>
                    </Col>

                    <Col span={8}>
                      <span>Total Line Net Amount</span>
                      <br />
                      <b>{totalLineNet}</b>
                    </Col>

                    <Col span={8}>
                      <span>Total Transaction Cost</span>
                      <br />
                      <b>{totalTrxCost}</b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginBottom: "8px" }}>
          <Card>
            <Table sticky={true} size="small" pagination={false} scroll={{ y: "58vh", x: "100%" }} dataSource={gridData} columns={headerData} />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default SalesGPReport;
