import React, { useEffect, useState } from "react";
import { Table, Typography, Input, Space, Button, DatePicker } from "antd";
import { Resizable } from 'react-resizable';
import ReactDragListView from "react-drag-listview";
import "../../styles/app.css";
const { Text } = Typography;

const ResizableCell = (props) => {
  const { onResize, width, ...restProps} = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const TableForReport = (props) => {
  const { columnsData, gridData,reportFields } = props;
  const [columns, setColumns] = useState([]);
  const userPreferences = JSON.parse(localStorage.getItem("userPreferences"));
  const roundOffDecimalValue = userPreferences.decimalPlaces;
  const amountArray = []
  for (let index = 0; index < reportFields.length; index++) {
    const element = reportFields[index];
    if(element.type==="Amount"){
      amountArray.push(element.fieldName)
    }
  }

  if (gridData.length > 0) {   
    for (let index = 0; index < gridData.length; index++) {
      const element = gridData[index];
      for (let index2 = 0; index2 < amountArray.length; index2++) {
        const element2 = amountArray[index2];
        element[element2] = parseInt(element[element2]);
      }
    }            
  }
  
  useEffect(() => {
    let data = [ ...columnsData];
    const handleReset = clearFilters => {
      clearFilters();
    };
    const getColumnSearchProps = (type) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          {type === "Date" ? 
            <DatePicker
              onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
              style={{ marginBottom: 8, display: 'block' }}
            /> : 
            <Input
              value={selectedKeys[0]}
              onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
              style={{ marginBottom: 8, display: 'block' }}
            />
          }
          <Space>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              size="small"
            >
              Filter
            </Button>
          </Space>
        </div>
      )
    });
    for (let index = 0; index < data.length; index++) {
      Object.assign(data[index], getColumnSearchProps(data[index].type), { filteredValue : null }, { 
        onFilter: (value, record) => 
          record[data[index].dataIndex]
          ? record[data[index].dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : ''              
      });
    };
    setColumns(columnsData);
  }, [columnsData]);

  const handleChange = (pagination, filters) => {
    let data = [ ...columns];
    for (let index1 = 0; index1 < Object.keys(filters).length; index1++) {
      for (let index2 = 0; index2 < data.length; index2++) {
        if (Object.keys(filters)[index1] === data[index2].dataIndex) {
          data[index2].filteredValue = Object.values(filters)[index1];
        }
      }
    }
    setColumns(data);
  };

  const components = {
    header: {
      cell: ResizableCell
    }
  };

  const finalColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: columns => ({
      title: columns.title,
      width: columns.width,
      onResize: handleResize(index),
    }),
  }));
  const handleResize = index => (e, { size }) => {
    setColumns((columns) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return nextColumns;
    });
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex){
      const resizeColumns = [...columns];
      const item = resizeColumns.splice(fromIndex,1)[0];
      resizeColumns.splice(toIndex, 0, item);
      setColumns(resizeColumns);
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    ignoreSelector: "react-resizable-handle"
  };

  return (
    <div>
      <ReactDragListView.DragColumn {...dragProps}>
      <Table
        size="small"
        sticky={true}
        scroll={{ y: "58vh", x: "100%" }}
        columns={finalColumns}
        dataSource={gridData}
        components={components}
        pagination={false}
        onChange={handleChange}
        summary={(pageData) => {
          let totalArr = [];
          let finalArr = [];
          let summaryFields = [];
          for (let index = 0; index < finalColumns.length; index++) {
            summaryFields.push(finalColumns[index].dataIndex);
          };
          for (let index = 0; index < summaryFields.length; index++) {
            totalArr[index] = 0;
          }
          for (let index = 0; index < pageData.length; index++) {
            const element = pageData[index];
            for (let index1 = 0; index1 < summaryFields.length; index1++) {
              totalArr[index1] += element[summaryFields[index1]];
            }
          }
          for (let index = 0; index < totalArr.length; index++) {
            const element = totalArr[index];
            
            if (typeof element === "string") {
              finalArr.push(" ");
            } else {
              finalArr.push(element.toFixed(roundOffDecimalValue));
            }
          }
          finalArr.splice(0, 1, "Total");
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {summaryFields.map((data, indexNum) => {
                  return (
                    <Table.Summary.Cell>
                      <Text>{finalArr[indexNum]}</Text>
                    </Table.Summary.Cell>
                  );
                })}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      </ReactDragListView.DragColumn>
    </div>
  );
};

export default TableForReport;
