import React, { useState } from "react";
import { Card, Col, Row, Table, Form, DatePicker, Select, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getCustomBusinessUnitForBalanceSheet, getBalanceSheetData } from "../../../services/generic";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const ProfitLossStatement = () => {
    const [bunitData,setBunitData] = useState([]);
    const [mainRowData, setMainRowData] = useState([]);
    const [mainColumnData, setMainColumnData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [headerform] = Form.useForm();

    const getBusinessUnit = async () =>{
        const businessUnitResponse = await getCustomBusinessUnitForBalanceSheet();
        setBunitData(businessUnitResponse);
    };

    const onFinish = async (values) => {
        setLoading(true);
        const valuesArray = Object.values(values);
        let valuesObj = {};
        valuesObj['cs_bunit_id'] = "7310D14D16CB4FD5B860D3A55295CFBD";
        valuesObj['datefrom'] = valuesArray[1].format("YYYY-MM-DD")
        valuesObj['dateto'] = valuesArray[2].format("YYYY-MM-DD")

        const stringifiedJSON = JSON.stringify(valuesObj);
        const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');

        const serverResponse = await getBalanceSheetData(jsonToSend);

        if (serverResponse) {
            const data = JSON.parse(serverResponse.data.data.executeAPIBuilder); console.log(data);

            const toTree = (arr) => {
                for (let index = 0; index < arr.length; index++) {
                    arr[index].key = index;
                };
                console.log(arr);
                const arrMap = new Map(arr.map(item => [item.accountcode, item]));
                const tree = [];
                for (let index = 0; index < arr.length; index++) {
                  const item = arr[index];
                  if (item.parentaccount) {
                    const parentItem = arrMap.get(item.parentaccount);
                    if (parentItem) {
                      const { children } = parentItem;
                      if (children) {
                        parentItem.children.push(item);
                      } else {
                        parentItem.children = [item];
                      }
                    }
                  } else {
                    tree.push(item);
                  }
                }
                return tree;
            };

            const treeRowData = toTree(data);

            const columns = [
                // {
                //     "title": "Parent Name",
                //     "dataIndex": "parentname",
                //     "key": "parentname"
                //   },
                //   {
                //     "title": "Parent Account",
                //     "dataIndex": "parentaccount",
                //     "key": "parentaccount",
                //   },
                  {
                    "title": "Account Code",
                    "dataIndex": "accountcode",
                    "key": "accountcode",
                  },
                  {
                    "title": "Account Name",
                    "dataIndex": "accountname",
                    "key": "accountname",
                  },
                  {
                    "title": "Value",
                    "dataIndex": "value",
                    "key": "value",
                  }
            ];
            setLoading(false);
            setMainRowData(treeRowData);
            setMainColumnData(columns);
        }
    };

    const handleConfirm = () => {
        headerform.submit();
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Balance Sheet</h2>
                </Col>
            </Row>
            <br/>
            <Row gutter={8}>
                <Col span={24} style={{ marginBottom: "8px" }}>
                    <Card>
                        <Row gutter={8}>
                            <Col span={20}>
                                <Form layout="vertical" form={headerform} onFinish={onFinish}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item name="businessUnit" label="Business unit" style={{ marginBottom: "8px" }} rules={[{ required: true }]} >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    onFocus={getBusinessUnit}
                                                >
                                                    {bunitData.map((data) => (
                                                        <Option key={data.recordid} value={data.recordid} title={data.name}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={2.8}>
                                            <Form.Item name="datefrom" label="From Date" style={{ marginBottom: "8px" }} rules={[{ required: true }]} >
                                                <DatePicker format={dateFormat} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={2.8}>
                                            <Form.Item name="dateto" label="To Date" style={{ marginBottom: "8px" }} rules={[{ required: true }]} >
                                                <DatePicker format={dateFormat} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col span={4}>
                                <Button  style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px", float: "right", marginTop: "10%" }} onClick={handleConfirm}>
                                    View
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Table
                dataSource={mainRowData}
                columns={mainColumnData}
                pagination={false}
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
                }}
                scroll={{ y: "65vh", x: "100%" }}
            />
        </div>
    );
};

export default ProfitLossStatement;